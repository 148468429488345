exports.components = {
  "component---src-components-maker-page-template-js": () => import("./../../../src/components/maker_page_template.js" /* webpackChunkName: "component---src-components-maker-page-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/ComingSoon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-alpha-js": () => import("./../../../src/pages/listAlpha.js" /* webpackChunkName: "component---src-pages-list-alpha-js" */),
  "component---src-pages-list-perry-js": () => import("./../../../src/pages/listPerry.js" /* webpackChunkName: "component---src-pages-list-perry-js" */),
  "component---src-pages-list-rating-js": () => import("./../../../src/pages/listRating.js" /* webpackChunkName: "component---src-pages-list-rating-js" */),
  "component---src-pages-list-type-js": () => import("./../../../src/pages/listType.js" /* webpackChunkName: "component---src-pages-list-type-js" */),
  "component---src-pages-shopping-list-js": () => import("./../../../src/pages/shopping_list.js" /* webpackChunkName: "component---src-pages-shopping-list-js" */),
  "component---src-pages-whatsnew-js": () => import("./../../../src/pages/whatsnew.js" /* webpackChunkName: "component---src-pages-whatsnew-js" */)
}

